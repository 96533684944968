import React from 'react';
import MainHeader from 'components/templateParts/MainHeader';
import SearchBar from 'components/templateParts/SearchBar';
import SmartViewsSidebar from 'components/SmartViewsSidebar/SmartViewsSidebar'
import Footer from 'components/Footer/Footer';
import { BaseListComponent } from 'components/base'
import { smartFilterConfig, tableColConfig } from 'config/resources/freezerReverseLookup';
import ExportAllButton from 'components/ui_components/buttons/ExportAllButton.js';
import { compose } from 'redux'
import { withSmartFilter } from "SmartFilterProvider";
import { stringify } from 'query-string'

const Content = (props) => {
  const filter = props.smartFilter.getFilter();
  let filters = {};
  if (filter && Object.keys(filter).length > 0) {
    let prepare_filter = Object.keys(filter).map((field) => {
      return `${field}:${filter[field]}`;
    });
    filters = { filter: `[${prepare_filter}]` };
  }

  return (
    <div>
      <MainHeader title="Freezer Reverse Lookup"/>
      <SearchBar/>
      <SmartViewsSidebar isOpen={true}
                         resource="freezer_specs"
                         smartFilterConfig={smartFilterConfig}>
        <div className="mainContainer">
          <div className="mainContainer__inner">
            <BaseListComponent
              {...props}
              tableColConfig={tableColConfig}
              deleteConfirmationMessagePostfix="Freezer"
              handleRowClick={null}
              disableRowClick={true}
              toolbarExtraComponents={
                <ExportAllButton
                  resource={`freezer_specs/export?${stringify({ ...filters })}`}
                />
              }
              showDelete={false}
              showSelect={false}
              showSelectAll={false}
            />
          </div>
          <Footer/>
        </div>
      </SmartViewsSidebar>
    </div>
  );
}

const FreezerReverseLookup = compose(
  withSmartFilter(),
)(Content);


export default FreezerReverseLookup;